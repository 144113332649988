/* Themes */

:root {
    --root-bg: #fff;
    --body-bg: var(--root-bg);
    --root-txt-clr-A: #000000de;
    --root-txt-clr-B: #00000099;
    --root-dimmer: #000000d9;
    --nav-bg: #303030;
    --nav-txt-clr-A: #fff;
    --nav-txt-clr-B: #e4cbaf;
    --nav-logo-height: 35px;
    --nav-logo-padding: .2em 1.14285714em;
    --selection-menu-btn-img-icons: unset;
    --splitter-img-icon: unset;
    --accordion-product-category-img-icons: unset;
    --app-container-version-disp-bg: #e0e1e2;
    --app-container-version-disp-txt-A: #5a5a5b;
    --app-container-bg: var(--root-bg);
    --app-container-bg-left: var(--root-bg);
    --app-container-bg-right: var(--root-bg);
    --app-container-heading: #000000de;
    --app-container-sub-heading: #000000de;
    --app-container-disclaimer: #000000de;
    --selection-menu-bg: var(--root-bg);
    --selection-menu-btn-bg: #e0e1e2;
    --selection-menu-btn-bg-hover: #cacbcd;
    --selection-menu-btn-txt-A: #00000099;
    --selection-menu-btn-txt-B: #000000cc;
    --choose-model-bg: #00000000;
    --choose-model-txt-clr: #fff;
    --choose-model-return-bg: #00000000;
    --choose-model-return-bg-hover: #ff5144;
    --choose-model-return-txt-A: #ff695e;
    --choose-model-return-txt-B: #fff;
    --loader-txt-clr: #fff;
    --live-view-container-bg: var(--body-bg);
    --live-view-card-bg: var(--body-bg);
    --live-view-card-box-shadow-part-A-clr: #d4d4d5;
    --live-view-card-box-shadow-part-B-clr: #d4d4d5;
    --live-view-ctrl-btn-bg: #e0e1e2;
    --live-view-ctrl-btn-bg-hover: #cacbcd;
    --live-view-ctrl-btn-bg-active: #21ba45;
    --live-view-ctrl-btn-bg-active-hover: #16ab39;
    --live-view-ctrl-btn-txt-A: #00000099;
    --live-view-ctrl-btn-txt-B: #000000cc;
    --live-view-ctrl-btn-txt-C: #fff;
    --live-view-close-btn-bg: #dd4b39;
    --live-view-close-btn-bg-hover: #e0321c;
    --live-view-close-btn-txt-A: #fff;
    --live-view-close-btn-txt-B: #fff;
    --live-view-splitter-pole-bg-gradient-A: #fff;
    --live-view-splitter-pole-bg-gradient-B: #fff;
    --live-view-splitter-pole-bg-bg: #fff0;
    --live-view-splitter-pole-bg: linear-gradient(180deg, var(--live-view-splitter-pole-bg-gradient-A) 0% calc(100% - 65px), var(--live-view-splitter-pole-bg-gradient-B) calc(100% - 65px) 100%);
    --live-view-splitter-pole-handle-bg: #fff;
    --live-view-slider-handle-border: #303030;
    --live-view-slider-handle-border-hover: #303030;
    --live-view-slider-handle-border-active: #303030;
    --live-view-slider-handle-box-shadow-active: #303030;
    --live-view-slider-handle-border-focus: #303030;
    --live-view-slider-handle-box-shadow-focus: #303030;
    --live-view-slider-track-bg: #d6d6d6;
    --live-view-slider-dot-border-active: #303030;
    --live-view-slider-indicator-clr: #fff;
    --live-view-slider-bg: #3030308f;
    --color-pallet-container-border: 1px solid rgba(34, 36, 38, .15);
    --color-pallet-container-box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0);
    --category-switcher-or-bg-A: var(--root-bg);
    --category-switcher-or-bg-B: #fff;
    --category-switcher-or-txt-clr: #00000066;
    --category-switcher-selected-bg: #767676;
    --category-switcher-selected-txt-clr: #fff;
    --category-switcher-selected-hover-bg: #838383;
    --category-switcher-selected-hover-txt-clr: #fff;
    --category-switcher-non-selected-bg: #e0e1e2;
    --category-switcher-non-selected-txt-clr: #00000099;
    --category-switcher-non-selected-hover-bg: #cacbcd;
    --category-switcher-non-selected-hover-txt-clr: #000000cc;
    --accordion-bg: #fff;
    --accordion-txt-clr-A: #00000066;
    --accordion-txt-clr-B: #000000de;
    --accordion-carousel-mask-item-bg: #fff;
    --accordion-carousel-bg: #fff;
    --accordion-carousel-fg: #e0e1e2;
    --accordion-carousel-item-border-selected: #303030;
    --accordion-carousel-item-border-not-selected: var(--accordion-carousel-fg);
    --accordion-carousel-item-border-hover: #303030;
    --accordion-carousel-move-btn-bg: #e0e1e2;
    --accordion-carousel-move-btn-bg-hover: #cacbcd;
    --accordion-carousel-move-btn-txt-clr: #00000099;
    --accordion-carousel-move-btn-txt-clr-hover: #000000cc;
    --shopping-cart-bg: #fff;
}

@media only screen and (max-width: 990px) {
    .selection-menu-container {
        zoom: 70% !important
    }
}

@media only screen and (max-height: 841px) {
    .selection-menu-container {
        zoom: 75% !important
    }
}

@media only screen and (max-width: 990px) {
    selection-menu-container: {
        zoom: 70% !important
    }
}

@media only screen and (max-width: 990px) {
    .custom-nav {
        --nav-bg: #e4927c !important;
    }
    .main-page-bg {
        /* --root-bg : #e4927c !important;
      --body-bg : #e4927c !important;
      --app-container-bg : #e4927c !important;
      --app-container-bg-left : #e4927c !important;
      --app-container-bg-right : #e4927c !important;
      --selection-menu-bg: #e4927c !important; */
        background: #e4927c !important;
    }
}

.shopping-price {
    font-weight: 400;
}

.shopping-rating {}

.shopping-now {
    float: left !important;
}

.loading-gif {
    width: 320px;
}

#root {
    min-width: 350px;
    min-height: calc(100vh - 66px) !important;
    max-height: 100vh !important;
    height: inherit;
    background-color: var(--root-bg) !important;
}

body {
    background-color: var(--body-bg);
}

.camera-toggle-icon {
    width: 35px;
    height: auto;
    opacity: 0.65;
}


/*image corrections */

.selection-menu-btn-img-icons {
    filter: var(--selection-menu-btn-img-icons) !important;
}

.splitter-img-icon {
    filter: var(--splitter-img-icon) !important;
}

.accordion-product-category-img-icons {
    filter: var(--accordion-product-category-img-icons) !important;
}

.application-logo {
    height: var(--nav-logo-height) !important;
}

.application-logo-container {
    padding: var(--nav-logo-padding) !important;
}

.modal-header>div {
    color: var(--choose-model-txt-clr) !important;
}

.ui.dimmer {
    background-color: var(--root-dimmer) !important;
}

.ui.button.toggle.active {
    color: var(--live-view-ctrl-btn-txt-C);
    background-color: var(--live-view-ctrl-btn-bg-active) !important;
}

.ui.button.toggle.active:hover {
    color: var(--live-view-ctrl-btn-txt-C);
    background-color: var(--live-view-ctrl-btn-bg-active-hover) !important;
}

.category-switcher-or {
    font-family: robo-bold;
    background-color: var(--category-switcher-or-bg-A) !important;
}

.category-switcher-or::before {
    background-color: var(--category-switcher-or-bg-B) !important;
    color: var(--category-switcher-or-txt-clr) !important;
}

.category-switcher-btn-grp.dev-selected {
    background-color: var(--category-switcher-selected-bg) !important;
    color: var(--category-switcher-selected-txt-clr) !important;
}

.category-switcher-btn-grp.dev-selected:hover {
    background-color: var(--category-switcher-selected-hover-bg) !important;
    color: var(--category-switcher-selected-hover-txt-clr) !important;
}

.category-switcher-btn-grp {
    background: var(--category-switcher-non-selected-bg) !important;
    color: var(--category-switcher-non-selected-txt-clr) !important;
}

.category-switcher-btn-grp:hover {
    background: var(--category-switcher-non-selected-hover-bg) !important;
    color: var(--category-switcher-non-selected-hover-txt-clr) !important;
}

.carousel-show-border {
    border: 2px solid var(--accordion-carousel-item-border-selected) !important;
}

.carousel-no-show-border {
    border: 2px solid var(--accordion-carousel-item-border-not-selected) !important;
}

.products-menu-title-height-active {
    height: 75px !important;
}

.products-menu-title-height-inactive {
    height: unset !important;
}

#splitter-image-container>canvas {
    height: auto;
}

#splitter-image-container_model_1>canvas,
#splitter-image-container_model_2>canvas,
#splitter-image-container_model_3>canvas,
#splitter-image-container_model_4>canvas {
    height: auto;
}

.canvas-container-fit-content {
    height: fit-content !important;
}

.canvas-container-100-percent {
    height: 100% !important;
}


/* toast */

.Toastify__toast-container--top-left {
    top: 60px !important;
    /* left: 1em !important; */
}

.toast-indicator-success {
    width: 10px;
    height: 10px;
    background-color: #58a900;
    margin: auto 5px;
}

.toast-indicator-err {
    width: 10px;
    height: 10px;
    background-color: #a90000;
    margin: auto 5px;
}

.toast-indicator-warn {
    width: 10px;
    height: 10px;
    background-color: #dccb00;
    margin: auto 5px;
}

.toast-indicator-info {
    width: 10px;
    height: 10px;
    background-color: #009fdc;
    margin: auto 5px;
}

.toast-progress-class-name-none {
    background: #9d7c57 !important;
}

.toast-heading-container {
    display: flex;
}

.toast-class-name {
    font-family: robo-reg;
    font-size: 1.2rem;
}

.toast-progress-class-name-success {
    background: #58a900;
}

.toast-progress-class-name-err {
    background: #a90000;
}

.toast-progress-class-name-warn {
    background: #dccb00;
}

.toast-progress-class-name-info {
    background: #009fdc;
}


/* rc slider */

.rc-slider-vertical {
    width: 14px !important;
    height: 152px !important;
    padding: 0 5px !important;
    margin: 10px auto !important;
}

.rc-slider-handle {
    border: 2px solid var(--live-view-slider-handle-border) !important;
}

.rc-slider-handle:hover {
    border: 2px solid var(--live-view-slider-handle-border-hover) !important;
}

.rc-slider-handle:active {
    border: 2px solid var(--live-view-slider-handle-border-active) !important;
    box-shadow: 0 0 5px var(--live-view-slider-handle-box-shadow-active) !important;
}

.rc-slider-handle:focus {
    border: 2px solid var(--live-view-slider-handle-border-focus) !important;
    box-shadow: 0 0 5px var(--live-view-slider-handle-box-shadow-focus) !important;
}

.rc-slider-track {
    background-color: var(--live-view-slider-track-bg) !important;
}

.rc-slider-dot-active {
    border-color: var(--live-view-slider-dot-border-active) !important;
}

.rc-slider-mark {
    font-family: robo-reg;
    font-size: 14px;
}


/* nudest */

.nudest-result-font {
    font-size: 1.4rem !important;
    font-family: robo-reg !important;
}


/* other */

.app-container-custom-grid2 {
    height: max-content !important;
}

#viewport_card_model_1 {
    margin: auto 0px 0px auto !important;
}

#viewport_card_model_2 {
    margin: auto auto 0px 0px !important;
}

#viewport_card_model_3 {
    margin: 0px 0px auto auto !important;
}

#viewport_card_model_4 {
    margin: 0px auto auto 0px !important;
}

.viewport_card_model {
    zoom: 0.6 !important;
}

#canvas_final_model_1,
#canvas_base_model_1,
#canvas_staging_model_1,
#canvas_splitter_model_1,
#canvas_final_model_2,
#canvas_base_model_2,
#canvas_staging_model_2,
#canvas_splitter_model_2,
#canvas_final_model_3,
#canvas_base_model_3,
#canvas_staging_model_3,
#canvas_splitter_model_3,
#canvas_final_model_4,
#canvas_base_model_4,
#canvas_staging_model_4,
#canvas_splitter_model_4 {
    max-width: 440px !important;
    max-height: 568px !important;
}

.nudest-foundation-carousel {
    overflow: hidden !important;
    padding: 1.75rem !important;
}

.live-view-grid-4-model-container {
    min-width: 750px !important;
    height: fit-content;
    zoom: 0.87;
}

.desktop-menu {
    display: block !important;
}

.mobile-menu {
    display: none !important;
}

#canvas_final,
#canvas_base,
#canvas_downsize,
#canvas_staging,
#canvas_splitter {
    max-width: 500px!important;
    max-height: 600px !important;
}

#canvas_base {
    max-width: 500px!important;
    max-height: 375px!important;
}

.sku-id-input,
.sku-name-input {
    min-width: 120px!important;
}

.mask-selection-dropdown {
    min-width: 180px!important;
}

@media only screen and (max-width: 960px) {
    #canvas_final,
    #canvas_base,
    #canvas_downsize,
    #canvas_staging,
    #canvas_splitter {
        max-width: 450px !important;
        max-height: 540px !important;
    }
    #canvas_base {
        max-width: 450px!important;
        max-height: 375px!important;
    }
    .viewport_card_model {
        zoom: 0.4 !important;
    }
    .mobile-menu {
        display: block !important;
    }
    .desktop-menu {
        display: none !important;
    }
    .app-container-custom-segment-group {
        height: max-content !important;
    }
    .app-container-custom-grid {
        height: max-content !important;
    }
}

@media only screen and (max-width: 600px) {
    .application-logo {
        height: 20px !important;
    }
    .category-switcher-btn-grp {
        font-size: 1rem !important;
    }
    .product-colo-menu-mask-img {
        height: 30px !important;
    }
    #canvas_final,
    #canvas_base,
    #canvas_downsize,
    #canvas_staging,
    #canvas_splitter {
        max-width: 400px !important;
        max-height: 460px !important;
    }
}

@media only screen and (max-width: 400px) {
    #canvas_final,
    #canvas_base,
    #canvas_downsize,
    #canvas_staging,
    #canvas_splitter {
        max-width: 350px !important;
        max-height: 375px !important;
    }
}

.center-content {
    /* height: calc(100vh - 370px) !important;
    overflow: auto; */
    /* height: auto !important; */
}

.ctaBannerContainer {
    width: 100%;
    float: left;
    margin-top: 10px;
    /* position: absolute;
    bottom: 0; */
}

.mianContainerCTA {
    width: 100%;
    float: left;
    min-height: 300px;
    padding: 30px 0px;
    background: linear-gradient( -15deg, #FEAC47, #F8486E 60%);
    height: auto;
}

.mainTitle {
    width: 70%;
    float: left;
    margin-left: 15%;
    color: #fff;
    font-size: 50px!important;
    line-height: 60px!important;
    margin-bottom: 20px!important;
    text-align: center;
}

.contactUsTitle {
    width: 70%;
    float: left;
    margin-left: 15%;
    color: #fff;
    font-size: 16px!important;
    line-height: 30px!important;
    margin-bottom: 20px!important;
    text-align: center;
}

.getStartedContainer {
    width: 70%;
    float: left;
    margin-left: 15%;
    color: #fff;
    margin-bottom: 20px!important;
    text-align: center;
    margin-top: 25px;
}

.anchorGetStarted {
    border-color: rgba(255, 255, 255, 0.75);
    color: rgb(255, 255, 255);
    visibility: visible;
    background-color: transparent;
    margin-top: 10px !important;
    padding: 15px 50px!important;
    border-radius: 16px!important;
    border-width: 3px;
    border-style: solid;
    font-weight: 600!important;
    text-transform: uppercase;
    margin-bottom: 0!important;
    transition: opacity .45s cubic-bezier(.25, 1, .33, 1), transform .45s cubic-bezier(.25, 1, .33, 1), border-color .45s cubic-bezier(.25, 1, .33, 1), color .45s cubic-bezier(.25, 1, .33, 1), background-color .45s cubic-bezier(.25, 1, .33, 1), box-shadow .45s cubic-bezier(.25, 1, .33, 1);
}

@media screen and (max-width: 980px) {
    .mianContainerCTA {
        min-height: 165px;
        padding: 10px 0px;
    }
    .mainTitle {
        font-size: 20px !important;
        line-height: 40px !important;
        margin-bottom: 10px !important;
    }
    .contactUsTitle {
        margin-bottom: 10px!important;
    }
    .getStartedContainer {
        margin-bottom: 10px!important;
        margin-top: 15px;
    }
    .anchorGetStarted {
        margin-top: 10px !important;
        padding: 10px 20px!important;
    }
}

@media screen and (max-width: 767px) {
    .ctaBannerContainer {
        display: none;
    }
}
/* consent modal */
.react-responsive-modal-container {
  font-family: sans-serif;
}

.react-responsive-modal-container .consent-text {
  margin     : 30px 15px 30px;
  line-height: 26px;
}

.react-responsive-modal-container .consent-button {
  margin: 0 auto 20px;
}

button.consent {
  border-radius  : 4px;
  border         : 1.5px solid #000;
  padding        : 10px 20px;
  font-size      : 16px;
  background-size: 300%;
  transition     : all 0.6s;
  margin         : 30px auto 0;
  display        : block;
  cursor         : pointer;
}

button.consent.green {
  color           : black;
  background-image: linear-gradient(90deg, white 50%, #000 50%);
}

button.consent:hover {
  color              : #fff;
  background-position: 100%;
}

button.consent:active {
  box-shadow: 0px 10px 20px -15px rgba(0, 0, 0, 0.75);
}

.consent-button a button.consent {
  margin: 0 auto;
}