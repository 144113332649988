@font-face {
    font-family: robo-thin;
    src: url(/Fonts/Roboto/Roboto-Thin.ttf);
}

@font-face {
    font-family: robo-reg;
    src: url(/Fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: robo-light;
    src: url(/Fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
    font-family: robo-med;
    src: url(/Fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
    font-family: robo-bold;
    src: url(/Fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
    font-family: robo-black;
    src: url(/Fonts/Roboto/Roboto-Black.ttf);
}


body {
    font-family: robo-thin, robo-reg, robo-light, robo-med, robo-bold, robo-black, 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}